import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";

interface PopupContextType {
  isPopupVisible: boolean;
  popupContent: ReactNode;
  showPopup: (content: ReactNode) => void;
  hidePopup: () => void;
}

export const PopupContext = createContext<PopupContextType | undefined>(
  undefined
);

interface PopupProviderProps {
  children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState<ReactNode>(null);

  const showPopup = (content: ReactNode) => {
    setPopupContent(content);
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupContent(null);
    setPopupVisible(false);
  };

  useEffect(() => {
    if (isPopupVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isPopupVisible]);

  return (
    <PopupContext.Provider
      value={{ isPopupVisible, popupContent, showPopup, hidePopup }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export const usePopupContext = (): PopupContextType => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopupContext must be used within a PopupProvider");
  }
  return context;
};
